'use strict'

import * as filestack from 'filestack-js'
import { actions, getters } from './store'

export const picker = {
    /**
     * Configure Vista api
     *  see https://create.vista.com/api-docs/?path=/story/usage-scenarios-init-options--sidebar-tabs-config
     **/
    async vistaInit(method, options = {}, user = { _id: 'platform.lately.ai' }) {
        console.log('vistaInit', options)
        // initialize for image or media oriented download
        let downloadFormat = options.intent === 'image' ? 'gif' : 'mp4'

        // todo - externalize this key
        let args = {
            apiKey: 'P9BDXT0-JBKMRS1-PH5DS10-8H51NHS',
            designType: 'facebookSM',
            // downloadFormat,
            user: {
                externalUserId: user._id, // defaults to platform.lately.ai if user is not provided
            },
            translations: {
                en: [
                    {
                        key: 'headerButtonPublish',
                        value: 'Attach to Post',
                    },
                ],
            },
        }

        if (options.intent === 'media') {
            args.sidebarTabs = ['video', 'music']
            args.sidebarTabsConfig = {
                video: true,
                music: ['explore', 'myMusic'],
                background: ['videos'],
                // library: ['purchased', 'uploads'],
            }
        } else {
            ;(args.sidebarTabs = ['designs', 'photos', 'text', 'styles', 'objects', 'background']), //, 'library', 'brandkit']
                (args.sidebarTabsConfig = {
                    designs: ['crello'],
                    photos: ['partner', 'premium', 'stock'],
                    background: ['colors', 'photos'],
                    // brandkit: true,
                    objects: ['animated', 'static'],
                    // library: ['purchased', 'uploads'],
                    styles: true,
                    text: ['fonts', 'styles'],
                })
        }

        args = Object.assign(args, options)
        let api = await window.VistaCreateEditor.init(args)
        console.log('vistaInit', args)
        if (method === 'update') {
            await api.addImages({
                images: [options.url],
            })
        }
        return api
    },

    /**
     * Open createMedia editor to create media
     * intent describes user intent to interact with image or media
     */
    async vistaCreate(post, channel, intent, user) {
        if (!user) throw new Error('user required')
        let options = {
            intent,
            designType: `${channel}SM`,

            // vista callback
            onPublishAction: async (file) => {
                let dashboard = getters.dashboard()
                let created = await actions.upload(dashboard._id, file)

                var intermediate = [created.data].map((f) => {
                    return {
                        file: f._id,
                        url: f.url,
                        duration: f.duration,
                        filename: f.filename,
                        mimetype: f.mimetype,
                        thumbnail: f.thumbnail,
                        title: f.title,
                        size: f.size,
                    }
                })

                // update and save the post
                post.attachments.push.apply(post.attachments, intermediate)
                await actions.updatePost({ _id: post.session, dashboard: dashboard._id }, post)
            },
        }
        await picker.vistaInit('create', options, user)
    },

    /**
     * Open createMedia editor
     */
    async vistaEdit(post, attachment, channel, policy, user) {
        if (!user) throw new Error('user required')
        console.log('vistaEdit', { channel, attachment })
        return new Promise(async (resolve, reject) => {
            let url = attachment.url.includes('filestack') ? `${attachment.url}?policy=${policy.policy}&signature=${policy.signature}` : attachment.url
            let options = {
                intent: attachment.mimetype.split('/')[0] === 'image' ? 'image' : 'media',
                designType: `${channel}SM`,
                url: url,
                onPublishAction: async (file) => {
                    console.log('onPublishAction', { attachment, file })
                    let existing = post.attachments.find((a) => {
                        return a.url === attachment.url
                    })

                    let dashboard = getters.dashboard()

                    let updated = await actions.replace(dashboard._id, post, attachment, file)

                    console.log('edited', { updated, existing })
                    let idx = post.attachments.indexOf(existing)

                    // detach existing
                    post.attachments.splice(idx, 1)

                    // edit existing
                    existing.url = updated.url

                    // reattach existing
                    post.attachments.splice(idx, 0, existing)

                    resolve(post)
                },
            }
            await picker.vistaInit('update', options, user)
        })
    },

    pickFromCloud(session, options, policy) {
        return new Promise(async (resolve, reject) => {
            // default config goes to cdnlately-v3 bucket
            const defaults = {
                lang: 'en',
                uploadConfig: {
                    intelligent: true,
                },
                fromSources: ['local_file_system', 'imagesearch'], // options.fromSources,
                accept: options.accept,
                minFiles: options.minFiles || 1,
                maxFiles: options.maxFiles || 1,
                storeTo: {
                    container: 'cdnlately-v3', // updated
                    region: 'us-east-1',
                    access: 'public',
                    location: 's3',
                },
            }

            options.modalSize = [1024, 768]

            defaults.onUploadDone = async (results) => {
                console.log('picker.onUploadDone', results)
                for (let idx in results.filesUploaded) {
                    const f = results.filesUploaded[idx]

                    try {
                        const file = await actions.findOrCreateFile(session, f)
                        if (file) {
                            f._id = file._id
                        } else {
                            console.error('Unable to find or create file for uploaded', JSON.stringify(f, 0, 1))
                        }
                    } catch (err) {
                        // what to do here
                        console.error('findOrCreateFile caught', err)
                        this.$toasted.error(`Failed to upload file. Please try again, or select a different file`)
                    }
                }

                resolve(results)
            }

            const client = filestack.init(process.env.VUE_APP_FILESTACK_KEY, { security: policy })
            client.picker(defaults).open()
        })
    },
}
