<template>
  <div id="app">
    <transition name="fade">
      <component :is="layout">
        <router-view :key="$route.fullPath"/>
      </component>
    </transition>
  </div>
</template>

<script>
import '@/scss/app.scss'
import '@/scss/global.scss'

export default {
  computed: {
    layout() {
      return `layout-${this.$route.meta.layout || 'default'}`
    }
  }
}
</script>

<style lang="scss" >

.nomargin {
  margin: 0px!important;
}

.nopad {
  padding: 0px!important;
}

.modal {
  background-color: rgba(32, 199, 99, 0.7);
  &.onboarding {
    background:linear-gradient(to top right, #A8FCF7, #FEFFFF)!important;
    background-image: url('~@/assets/onboarding/v2/bg1.png'); 
    background-repeat: no-repeat;
    background-size: 100% 100%;    
  }
  &.posts-ready {
    .modal-backdrop {
      opacity: 0.6;
      background-color: #095153;
      // background-color: transparent!important;
    }
  }  
}

.pseudo-link {
  color: #42b983
}

/**
node v20 upgrade appears to have broken vue date/time picker styling
correct inline for now
*/

.b-calendar-inner {
  padding: 10px;
  .b-calendar-header {
    border: 0px!important;
    text-align: center;
    width: 100%;
  }
  .b-calendar-nav {
    padding: 5px;
  }
  .b-calendar-grid {
    border: 0px;
  }
  .btn {
    width: 32px;
    min-height: 32px;
    font-size: 14px;
    padding: 9px 0px;

  }
}

.date-picker {
  label {
    display: inline;
    font-size: 1rem;
    margin: 0px;
    border: 0px;    
  }
  button {
    padding: 0px;
  }
}

.time-picker {
  margin-bottom: 0px;
  label {
    display: inline;
    font-size: 1rem;    
    margin: 0px;
    border: 0px;    
  }
  .b-form-timepicker {
    button {
      margin:0px;
      padding: 0px;
    }

    .dropdown-menu.show {
      padding: 10px;
    }

    .input-group[role="group"] {
      .input-group-prepend  {
        font-size: 1rem;    
        button {
          border: 1px 0px 0px 0px solid lightgrey!important;
          border-radius: 5px 0px 0px 5px;
        }
        input {
          border: 0px!important; // 1px 1px 0px 1px solid lightgrey;
          border-radius: 5px 0px 0px 5px;
          font-size: 13px;
        }
      }
    }
  }

  .b-time {  

    .b-time-header {
      margin-bottom: 10px;
    }
    div[role="group"] {
      min-height: 140px;
      margin-bottom: 5px;
    }
    .b-time-footer {
      button {
        padding: 5px;
      }
    }

    .b-form-spinbutton.b-time-ampm {
      margin-left: 5px;
    }
  }
  
}

</style>
