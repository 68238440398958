<template>
  <div class="container-fluid">
    <div class="signup-page account-info container">
      <b-navbar class="fixed-top" toggleable="lg" type="light">

        <div class="container nopad d-flex justify-content-between align-items-center">

          <b-navbar-brand href="https://www.lately.ai">
            <a class="navbar-brand">
              <img width="100" src="@/assets/lately_logo_nav.jpg" />
            </a>
          </b-navbar-brand>

          <b-navbar-nav class="ml-auto">
            <a href="/#/app/login" class="sign-in-btn">LOG IN</a>
          </b-navbar-nav>

        </div>
      </b-navbar>
      <div class="row">
        <div class="col-lg-6">
          <h1>
            Welcome to Lately's "Starter Plan" 7-day Boot Camp!
          </h1>
          <p>Ideal for individuals and micro-businesses that want to repurpose longform content with basic scheduling and analytics needs.</p>
          <div class="checklist">
            <ul>
              <li><span class="check-icon"><i class="fas fa-check-circle"></i></span> Free to try</li>
              <li><span class="check-icon"><i class="fas fa-check-circle"></i></span> Easy to set up</li>
              <li><span class="check-icon"><i class="fas fa-check-circle"></i></span> Cancel anytime!</li>
            </ul>
          </div>
          <p class="cta"><b>Join 20,000+ marketers from businesses of all shapes, sizes and sectors that trust Lately to breathe new life into existing content.</b></p>

          <div class="people">
            <img src="@/assets/signup/david_allison.jpg" alt="David Allison"></img>
            <img src="@/assets/signup/lynn_abate_johnson.jpg" alt="Lynn Abate Johnson"></img>            
            <img src="@/assets/signup/mark_wald.jpg" alt="Mark Wald"></img>           
            <img src="@/assets/signup/david_meerman_scott.jpg" alt="David Meerman Scott"></img>                     
            <img src="@/assets/signup/lynn_wunderman.jpg" alt="Lynn Wunderman"></img>                   
            <img src="@/assets/signup/david_beckford.jpg" alt="David Beckford"></img>                                          
          </div>
        </div>

        <div class="col-lg-6">
          <form class="signup-form" @submit.prevent="submit">
            <div class="form-group">
              <label for="email"><strong>Email</strong> <span class="required">(required)</span></label>
              <input type="email" class="form-control" id="email" name="email" v-model="user.email"
                @blur="validateField('email')" :class="{ 'is-invalid': touched.email && touched.psswdconf && errors.email }" required />
              <small v-if="touched.email && touched.psswdconf && errors.email" class="text-danger">Please enter a valid email address.</small>
              <div v-if="message" class="message w-100">
              <p class="text-danger" v-if="message === 'exists-ss'">Please use another email address.</p>
              <p class="text-danger" v-else-if="message === 'exists-lately'">That email address is associated with a
                Lately Enterprisely account. Please use another email address, or <a
                  :href="'https://dashboard.lately.ai/login?email=' + user.email">Sign In to my Enterprisely Account</a>
              </p>
              <p class="text-danger" v-else>{{ message }}</p>
            </div>

            </div>


            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="firstName"><strong>First Name</strong> <span class="required">(required)</span></label>
                <input type="text" class="form-control" id="firstName" name="firstName" v-model="user.firstName"
                  @blur="validateField('firstName')" :class="{ 'is-invalid': touched.firstName && touched.psswdconf && errors.firstName }"
                  required />
                <small v-if="touched.firstName && touched.psswdconf && errors.firstName" class="text-danger">First name is required.</small>
              </div>
              <div class="form-group col-md-6">
                <label for="lastName"><strong>Last Name</strong> <span class="required">(required)</span></label>
                <input type="text" class="form-control" id="lastName" name="lastName" v-model="user.lastName"
                  @blur="validateField('lastName')" :class="{ 'is-invalid': touched.lastName && touched.psswdconf  && errors.lastName }"
                  required />
                <small v-if="touched.lastName && touched.psswdconf && errors.lastName" class="text-danger">Last name is required.</small>
              </div>
            </div>


            <div class="form-group">
              <label for="password"><strong>Password</strong> <span class="required">(required)</span></label>
              <input type="password" class="form-control" id="password" name="password" v-model="user.psswd"
                @blur="validateField('psswd')" :class="{ 'is-invalid': touched.psswd && touched.psswdconf && errors.psswd }" required />
              <small v-if="touched.psswd && touched.psswdconf && errors.psswd" class="text-danger">Password must be at least 8 characters
                long.</small>
            </div>

            <div class="form-group">
              <label for="passwordConf"><strong>Confirm Password</strong> <span class="required">(required)</span></label>
              <input type="password" class="form-control" id="passwordConf" name="passwordConf" v-model="user.psswdconf"
                @blur="validateField('psswdconf')" :class="{ 'is-invalid': touched.psswdconf && errors.psswdconf }"
                required />
              <small v-if="touched.psswdconf && errors.psswdconf" class="text-danger">Passwords do not match.</small>
            </div>

            <div class="col-md-12 d-flex justify-content-center mb-2" v-if="recaptchaKey">
              <vue-recaptcha class="recaptcha" @verify="verify" :sitekey="recaptchaKey"
                :loadRecaptchaScript="true"></vue-recaptcha>
              <small v-if="showErrors && errors.recaptcha" class="text-danger">Please complete the reCAPTCHA.</small>
            </div>

            <button type="submit" class="btn btn-success btn-block" :disabled="!isFormValid">GET STARTED FREE</button>

            <p class="terms mt-3">
              By signing up, you agree to Lately’s <a target="blank" href="https://lately.ai/terms">Terms of Service</a> and <a target="blank" href="https://lately.ai/privacy">Privacy Policy</a>.
            </p>
          </form>
        </div>

        <!--
        <div class="col-sm-12">

          <div class="trusted-by-section text-center">
            <p class="trusted-by-text">Trusted to drive more revenue for businesses of all shapes and sizes.</p>
            <div class="row justify-content-center align-items-center">
              <div class="col-3 col-sm-3 col-md-2 my-2">
                <img src="@/assets/integrations/signify.png" alt="Signify" class="img-fluid">
              </div>
              <div class="col-3 col-sm-3 col-md-2 my-2">
                <img src="@/assets/integrations/sap.png" alt="SAP" class="img-fluid">
              </div>
              <div class="col-3 col-sm-3 col-md-2 my-2">
                <img src="@/assets/integrations/usnews.png" alt="US News" class="img-fluid">
              </div>                
              <div class="col-3 col-sm-3 col-md-2 my-2">
                <img src="@/assets/integrations/modernluxury.png" alt="Modern Luxury" class="img-fluid">
              </div>                                      
              <div class="col-3 col-sm-3 col-md-2 my-2">
                <img src="@/assets/integrations/everydayhealth.png" alt="Everyday Health" class="img-fluid">
              </div>
              <div class="col-3 col-sm-3 col-md-2 my-2">
                <img src="@/assets/integrations/husky.png" alt="HUSKY" class="img-fluid">
              </div>
              <div class="col-3 col-sm-3 col-md-2 my-2">
                <img src="@/assets/integrations/dhl.png" alt="StreamYard" class="img-fluid">
              </div>
              <div class="col-3 col-sm-3 col-md-2 my-2">
                <img src="@/assets/integrations/cox.png" alt="Dermalogica" class="img-fluid">
              </div>
            </div>
          </div>

        </div>
        -->

      </div>
    </div>
  </div>
</template>


<script>
'use strict'


import VueRecaptcha from 'vue-recaptcha';


export default {

  name: 'Account1',

  data() {
    return {
      user: {
        email: '',
        firstName: '',
        lastName: '',
        psswd: '',
        psswdconf: '',
        website: '',
        recaptcha: '',
      },
      showErrors: false,
      termsAccepted: false,
      recaptchaKey: '',
      errors: {
        recaptcha: false,
        email: true,
        firstName: true,
        lastName: true,
        psswd: true,
        psswdconf: true,
      },
      touched: {
        email: false,
        firstName: false,
        lastName: false,
        psswd: false,
        psswdconf: false,
      }
    }
  },

  props: {
    message: {
      type: String,
      default: ''
    }
  },

  created() {
    let isLocal = process.env['VUE_APP_LATELY_ENV'] === 'local'
    if (!isLocal) {
      this.mark('recaptcha', false);
      this.recaptchaKey = process.env['VUE_APP_RECAPTCHA_KEY']
    }
  },

  methods: {
    verify(token) {
      this.user.recaptcha = token
      this.mark('recaptcha', true)
    },
    mark(field, val) {
      this.$set(this.errors, field, !val);
    },
    validateField(field) {
      this.$set(this.touched, field, true); // Mark the field as touched
      switch (field) {
        case 'email':
          this.mark('email', this.vemail);
          break;
        case 'firstName':
          this.mark('firstName', !!this.user.firstName);
          break;
        case 'lastName':
          this.mark('lastName', !!this.user.lastName);
          break;
        case 'psswd':
          this.mark('psswd', this.vpassword);
          break;
        case 'psswdconf':
          this.mark('psswdconf', this.vpasswordConf);
          break;
      }
    },
    submit() {
      this.showErrors = Object.values(this.errors).some(error => error);
      if (this.showErrors) return;
      this.$emit('account-info-selected', this.user);
    }
  },


  computed: {
    vemail() {
      const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      return re.test(this.user.email);
    },
    vpassword() {
      return typeof this.user.psswd === 'string' && this.user.psswd.length >= 8;
    },
    vpasswordConf() {
      return this.user.psswd === this.user.psswdconf;
    },
    isFormValid() {
      return !Object.values(this.errors).some(error => error);
    }
  },


  components: {
    VueRecaptcha
  }


}
</script>

<style scoped>

.checklist ul {
  list-style-type: none;
  padding: 0;
  font-size: 15px;
  color: #6c757d;
}

.checklist li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.check-icon {
  color: #28a745;
  /* Green color */
  font-weight: bold;
  margin-right: 10px;
}

.signup-page {
  padding: 50px 0;
  background-color: transparent;
}

.signup-form {
  background-color: white;
}

h1 {
  font-family: 'Noto Sans';
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 20px;
  color: #000;
}

p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #000;
}

.signup-form {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.signup-form label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.terms {
  font-size: 12px;
  color: #6c757d;
  text-align: left;
}

.trusted-by-section {
  padding: 20px 0;
}

.trusted-by-text {
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 20px;
  color: #555;
}

.img-fluid {
  max-height: 50px;
  object-fit: contain;
}

.required {
  font-weight: normal;
}

.sign-in-btn {
  color: #20C864;
  font-size: 16px;
  font-weight: bold;
}

.eulac {
  font-size: 16px;
  margin: 15px 0px 15px 0px;

  p {
    margin-top: 0px;
    margin-bottom: 36px;
  }

  input {
    font-size: 22px !important;
  }
}

</style>
