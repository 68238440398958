'use strict'

import Vue from 'vue'

import VueRouter from 'vue-router'
import Login from '@/views/Login'
import Close from '@/views/Close'
import Start from '@/views/Start'
import Calendar from '@/views/Calendar'
import Drafts from '@/views/Drafts'
import Logout from '@/views/Logout'
import Session from '@/views/Session'
import Sessions from '@/views/Sessions'
import Password from '@/views/Password'
import Admin from '@/components/AdminPanel'

import ResultsV3 from '@/components/Results/v3'

import Logger from '@/services/logger'
import OnboardingService from '@/services/onboarding'

import Team from '@/components/Team'
import Dash from '@/components/Dashboard'
import Signup from '@/components/Signup/v2'
import Goals from '@/components/GoalsEditor'
import Settings from '@/components/Settings'
import Onboarding from '@/components/Onboarding/v7'
import KeyMessages from '@/components/KeyMessages'
import Articles from '@/components/Articles'

import Analytics from '@/components/Analytics/v2'

import GatherWebsite from '@/components/GatherWebsite'
import PublisherView from '@/components/PublisherView'
import InvitationView from '@/components/InvitationView'

import { actions, getters, authenticated, admin } from '@/services/store.js'

// register layouts
import LDefault from '@/layouts/default'
Vue.component('LayoutDefault', LDefault)

// import LBlank from '@/layouts/blank'
// Vue.component('LayoutBlank', LBlank)

Vue.use(VueRouter)

const routes = [
    {
        path: '/:dashboardId/admin',
        name: 'Admin',
        component: Admin,
        meta: {
            requiresAdmin: true,
            requiresAuth: true,
            layout: 'default',
        },
    },
    {
        path: '/:dashboardId/settings',
        name: 'Settings',
        component: Settings,
        meta: {
            requiresAuth: true,
            layout: 'default',
        },
    },
    {
        path: '/:dashboardId',
        name: 'Dash',
        component: Dash,
        meta: {
            requiresAuth: true,
            layout: 'default',
        },
    },

    {
        path: '/:dashboardId/start',
        name: 'Start',
        component: Start,
        meta: {
            requiresAuth: true,
            layout: 'default',
            backgroundColor: '#F8F7F4',
        },
    },
    {
        path: '/:dashboardId/generate/:sessionId/:count',
        name: 'Generate Content',
        component: Session,
        meta: {
            requiresAuth: true,
            layout: 'default',
        },
    },
    {
        path: '/:dashboardId/onboarding',
        name: 'Onboarding',
        component: Onboarding,
        meta: {
            requiresAuth: true,
            layout: 'default',
            hideExpiryBanner: true,
            backgroundColor: 'white',
        },
    },
    {
        path: '/:dashboardId/messaging',
        name: 'Messaging',
        component: KeyMessages,
        meta: {
            requiresAuth: true,
            layout: 'default',
        },
    },
    {
        path: '/:dashboardId/articles',
        name: 'Articles',
        component: Articles,
        meta: {
            requiresAuth: true,
            layout: 'default',
        },
    },
    {
        path: '/:dashboardId/invitation/:invitationId',
        name: 'Invitation',
        component: InvitationView,
        meta: {
            requiresAuth: false,
            layout: 'default',
        },
    },
    {
        path: '/:dashboardId/team',
        name: 'Team',
        component: Team,
        meta: {
            requiresAuth: true,
            layout: 'default',
        },
    },
    {
        path: '/:dashboardId/publisher',
        name: 'Publisher',
        component: PublisherView,
        meta: {
            requiresAuth: true,
            layout: 'default',
        },
    },
    {
        path: '/:dashboardId/website',
        name: 'Website',
        component: GatherWebsite,
        meta: {
            requiresAuth: true,
            layout: 'default',
        },
    },
    {
        path: '/:dashboardId/goals',
        name: 'Goals',
        component: Goals,
        meta: {
            requiresAuth: true,
            layout: 'default',
        },
    },
    {
        path: '/:dashboardId/drafts',
        name: 'Drafts',
        component: Drafts,
        meta: {
            requiresAuth: true,
            layout: 'default',
        },
    },
    {
        path: '/:dashboardId/results/:sessionId',
        name: 'Results',
        component: ResultsV3,
        meta: {
            requiresAuth: true,
            layout: 'default',
        },
    },
    {
        path: '/:dashboardId/sources',
        name: 'Sources',
        component: Sessions,
        meta: {
            requiresAuth: true,
            layout: 'default',
        },
    },
    {
        path: '/:dashboardId/analytics',
        name: 'Analytics',
        component: Analytics,
        meta: {
            requiresAuth: true,
            layout: 'default',
        },
    },
    {
        path: '/:dashboardId/calendar',
        name: 'Calendar',
        component: Calendar,
        meta: {
            requiresAuth: true,
            layout: 'default',
        },
    },
    {
        path: '/app/close',
        name: 'Close',
        component: Close,
        meta: {
            layout: 'default',
        },
    },
    {
        path: '/app/login',
        name: 'Login',
        component: Login,
        meta: {
            layout: 'default',
        },
    },
    {
        path: '/app/password',
        name: 'Change Password',
        component: Password,
        meta: {
            layout: 'default',
        },
    },
    {
        path: '/app/signup',
        name: 'Signup',
        hideSignupLink: true,
        component: Signup,
        meta: {
            layout: 'default',
        },
    },
    {
        path: '/app/subscription',
        name: 'Subscription',
        component: Signup,
        meta: {
            layout: 'default',
            requiresAuth: true,
            hideSignupLink: true,
            hideExpiryBanner: true,
        },
    },
    {
        path: '/app/logout',
        name: 'Logout',
        component: Logout,
        meta: {
            layout: 'default',
        },
    },

    { path: '*', redirect: '/app/login' },
]

const router = new VueRouter({
    linkActiveClass: 'active',
    //mode: 'history',
    routes: routes,

    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
})

router.afterEach(async (to, from, next) => {
    if (to && !to.path.startsWith('/app/') && authenticated()) {
        actions.setLastUrl(to.path)
    }
})

/**
 * Route guard handles member and admin routes
 * and will pre-empt route changes to signup
 * or onboarding states if necessary
 **/

router.beforeEach(async (to, from, next) => {
    OnboardingService.setHelpText()

    let _next = next
    next = (path) => {
        try {
            _next(path)
        } catch (err) {}
    }
    try {
        // log page view
        if (to.path !== from.path) {
            Logger.pageViewed(to.name)
        }

        document.body.classList.remove('no-nav')

        // router guard
        let preempt = await actions.init(false, to.path)

        // console.log('router.beforeEach', { preempt, proposed: to.path })

        if (to.matched.some((record) => record.meta.backgroundColor)) {
            document.body.style.backgroundColor = to.meta.backgroundColor || 'white'
        } else {
            document.body.style.backgroundColor = '#F9F9F9'
        }

        // find a matching route requiring auth
        if (to.matched.some((record) => record.meta.requiresAuth)) {
            // guard routes requiring auth
            if (authenticated()) {
                // if the desired route was pre-empted by the guard go there
                if (preempt && preempt != to.path) {
                    return next(preempt)
                }
                // admin route guard
                if (to.matched.some((record) => record.meta.requiresAdmin)) {
                    if (admin()) {
                        next()
                    } else next('/error?message=Denied')
                } else next()
            } else next('/app/login')
        }

        // a public route can also be pre-empted for hootsuite embedded use
        else if (preempt && preempt !== to.path && to.path !== '/app/logout') {
            if (to.path === '/app/login') {
                return next(preempt)
            } else return next()
        } else {
            next()
        }
    } catch (err) {
        console.error('Router caught', err)
    }
})

export default router
