<template>
  
  <div class="container-fluid onboarding-walkthru">
    <b-overlay :show="busy">
      <carousel ref="walkthru_carousel" v-model="slide" :controls="false" :per-page="1" :navigationEnabled="true" :paginationEnabled="false" @page-change="onPageChange">
      <slide>
        <div class="text-center">
          <div class="page ">
            <div class="container">            
              <h3 class="head">Welcome to Lately, your new AI Assistant</h3>
              <p class="info">Psst! Lately is not ChatGPT.. it's even BETTER!</p>
              <p class="actions">              
                <b-button class="next" @click="next(1)">CONTINUE</b-button>
              </p>
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="text-center">
          <div class="page">
            <div class="container">            
              <h3 class="head">Heads up, you don't prompt Lately to create social posts!</h3>
              <p class="info">Instead, Lately analyzes longform text, audio or video content, extracts snippets and transforms them into high performing social posts.</p>
              <p class="actions">     
                <small><a @click="next(-1)">Back</a></small>                     
                <b-button class="next" @click="next(1)">CONTINUE</b-button>
              </p>
            </div>
          </div>
        </div>      
      </slide>
      <slide>
        <div class="text-center">
          <div class="page d-flex align-items-center justify-content-center">
            <div class="container">
              <h3 class="head">But first, Lately examines your past social post analytic history and <span class="highlight">creates a unique "voice model" for you and you alone.</span></h3>
              <p class="actions">          
                <small><a @click="next(-1)">Back</a></small>                     
                <b-button class="next" @click="next(1)">CONTINUE</b-button>
              </p>
            </div>
          </div>
        </div>      
      </slide>    
      <slide>
        <div class="text-center">
          <div class="page d-flex align-items-center justify-content-center"> 
            <div class="container">            
              <h3 class="head">Lately will also enhance your voice model with best-practices <span class="highlight">tailored to your unique target audience.</span></h3>
              <p class="actions">           
                <small><a @click="next(-1)">Back</a></small>                                       
                <b-button class="next" @click="next(1)">CONTINUE</b-button>
              </p>
            </div>
          </div>
        </div>      
      </slide>         
      <slide>
        <div class="text-center">
          <div class="page d-flex align-items-center justify-content-center">
            <div class="container ">
              <h3 class="head">Ready to try a customized demo? </h3>
              <p class="info">For the purposes of this demo, we'll use a generic voice model, and start with the <b>longform text</b> of your choosing.</p>
              <p class="actions">             
                <small><a @click="next(-1)">Back</a></small>                     
                <b-button class="next" @click="next(1)">CONTINUE</b-button>
              </p>
            </div>
          </div>
        </div>      
      </slide>           
      <slide>
        <div class="text-center">
          <div class="page d-flex align-items-center justify-content-center">
            <div class="container">
              <h3 class="head">What kind of longform text do you want to use? </h3>
              <p class="info">(We'll start with text, for now. You can try audio or video later). </p>
              <div class="source-list">
                <div class="source-select">
                  <p class="text-left" @click="setSource('news-link')">
                    <i class="fa-circle" :class="source.type==='news-link'? 'fa':'far'" /> Newsletter link
                  </p>
                  <p class="text-left" @click="setSource('blog-link')">
                    <i class="fa-circle" :class="source.type==='blog-link'? 'fa':'far'" /> Blog Post link
                  </p>
                  <p class="text-left" @click="setSource('no-link')">
                    <i class="fa-circle" :class="source.type==='no-link'? 'fa':'far'" /> I don't have a link handy
                  </p>                                                                                        
                </div>
              </div>
              <p class="text-center">
                <span v-show="source.type==='no-link'">(that's ok, we'll loan you one for the demo 👊💚)</span>
              </p>              
              <p class="actions">
                <small><a @click="next(-1)">Back</a></small>                
                <b-button class="next" @click="next(1)">CONTINUE</b-button>
              </p>
            </div>
          </div>
        </div>      
      </slide>        
      <slide>
        <div class="text-center">
          <div class="page d-flex align-items-center justify-content-center">
            <div class="container">
              <h3 class="head">What channel do you want Lately to optimize your snippets for?</h3>
              <p class="info">Lately will ID the best snippets that will make your unique audience engage in line with algorithmic best practices for each channel.</p>
              <div class="channels-list">
                <div class="channel-list">
                  <p class="text-left" @click="setChannel('facebook-business')">
                    <i class="fa-circle" :class="source.channel==='facebook-business'? 'fa':'far'" /> Facebook Business Page
                  </p>
                  <p class="text-left" @click="setChannel('twitter-business')">
                    <i class="fa-circle" :class="source.channel==='twitter-business'? 'fa':'far'" /> X/Twitter Business Page
                  </p>
                  <p class="text-left" @click="setChannel('linkedin-business')">
                    <i class="fa-circle" :class="source.channel==='linkedin-business'? 'fa':'far'" /> LinkedIn Business Page
                  </p>                                                                                              
                </div>
                <div class="channel-list">  
                  <p class="text-left" @click="setChannel('instagram-business')">
                    <i class="fa-circle" :class="source.channel==='instagram-business'? 'fa':'far'" /> Instagram Business Page
                  </p>
                  <p class="text-left" @click="setChannel('twitter-personal')">
                    <i class="fa-circle" :class="source.channel==='twitter-personal'? 'fa':'far'" /> X/Twitter Personal Page
                  </p>
                  <p class="text-left" @click="setChannel('linkedin-personal')">
                    <i class="fa-circle" :class="source.channel==='linkedin-personal'? 'fa':'far'" /> LinkedIn Personal Page
                  </p>                                                                                                         
                </div>                
              </div>
              <p class="supplemental text-center">
                <small>(Instagram isn't available for your demo, but you can try it later!)</small>
              </p>              
              <p class="actions">
                <small><a @click="next(-1)">Back</a></small>
                <b-button class="next" @click="next(1)">CONTINUE</b-button>
              </p>
            </div>
          </div>
        </div>      
      </slide>     
      <slide>
        <div class="text-center">
          <div class="page d-flex align-items-center justify-content-center">
            <div class="">
              <h3 class="head">Paste the URL to the {{ sourceType() }} you want Lately to analyze.</h3>
              <p class="info" v-if="source.type!=='news-link'">(Make sure you paste in the <b>link to a specific blog post</b> and and not to the overall blog itself)</p>
              <div class="selected-url text-left">
                <label><b>Link to {{sourceType()}}</b></label>
                <input class="form-control" v-model="source.link" />     
                <p v-if="source.error"><small class="text-danger">{{ source.error }}</small></p>                         
              </div>
              <p class="supplemental text-center link-examples" v-if="source.type!=='news-link'">
                <small>For example: 
                  <span class="text-info"><i class="fa fa-check-circle" /> www.lately.ai/blog/fomo-is-dead</span> 
                  <span class="text-danger"><i class="fa fa-times-circle" /> www.lately.ai/blog</span>
                </small>
              </p>              
              <p class="actions">
                <small><a @click="next(-1)">Back</a></small>
                <b-button class="next" @click="complete()" :disabled="!validLink()">CONTINUE</b-button>
              </p>
            </div>
          </div>
        </div>      
      </slide>                          
      </carousel>  
    </b-overlay>
  </div>

</template>

<style lang="scss" >

.onboarding-walkthru {

  background-image: url('~@/assets/onboarding/background_01.png'); 
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 100vh;  
  padding-top: 25vh;
  flex: 1;

  .VueCarousel {

    width: 100%;

    .VueCarousel-navigation-button {
      font-family: Noto Sans;
      font-weight: bold;
      background-color: #42b983!important;
      color: white!important;
      border: 1px solid #42b983;
      padding: 5px 20px 5px 20px!important;
      border-radius: 4px;
    }

    .VueCarousel-navigation--disabled {
      display: none;
    }

    .VueCarousel-navigation-prev {
      display:none;
    }

    .VueCarousel-navigation-next {
      display: none;
    }

  }

  .page {

    width: 70vw;
    margin-left: 15%;

    h3.head {
      font-weight: bold;
      font-size: 28px;
    }

    p.info {
      font-size: 18px;
    }

    p.supplemental {
      margin-top: 10px;
      margin-bottom: 5px!important;
    }    

    .actions {
      margin-top: 30px;
      width: 70%;
      margin-left: 15%;
      a {
        margin-right: 20px;
        cursor: pointer;
        text-decoration: underline; 
        color: #42b983;
      }
      button.next {
        font-weight: bold;
        border-radius: 10px;
        padding: 10px 35px 10px 35px;
        background-color: #20C763;          
      }
    }


    .highlight {
      color: #20c763;
    }

    .source-list {

      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      width: 50%;
      margin-left: 25%;

      margin-bottom: 10px;
      background-color:#E2FBEE;
      padding: 10px 20px 10px 20px;
      border-radius: 20px;      
      input {
        width: 600px;
      }
      p {
        margin-top: 10px;
        margin-bottom: 5px!important;
      }
      i {
        background-color: white;
        border-radius: 10px;
      }
    }

    .channels-list {

      margin-left: 10px;
      max-width: 70vw;      

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      max-width: 70vw;

      margin-bottom: 0px;
      background-color:#E2FBEE;
      padding: 20px;
      border-radius: 10px;      
    }    

    .channel-list {
      i {
        background-color: white;
        border-radius: 10px;
      }

      p {
        font-size: 1em;
        font-weight: normal;
      }
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      margin-bottom: 0px;
      background-color:#E2FBEE;
      padding: 20px;
      border-radius: 10px;      
    }    

    .selected-url {

      background-color: green;
      width: 70%;
      margin-left: 15%;

      margin-bottom: 10px;
      background-color:#E2FBEE;
      padding: 20px 30px 35px 30px;
      border-radius: 20px;      
      input {
        width: 100%;
      }
      p {
        margin-top: 10px;
        margin-bottom: 5px!important;
      }

    }    

    .link-examples {
      i {
        margin: 0px 2px 0px 5px;
      }
    }

  }

}

</style>


<script>

import GeneratorSessionHelper from '@/services/generator-session'
import { Carousel, Slide } from 'vue-carousel'
import { actions } from '@/services/store'
import * as linkify from 'linkifyjs'

export default {
  name: 'Walkthru',
  data() {
      return {
        slide: 0,
        busy: false,
        source: {
          type: 'news-link',
          channel:'facebook-business',
          error: '',
          link:''
        }
      }
    },
    methods: {
      sourceType() {
        return this.source.type === 'news-link'? 'Newsletter' : 'Blog Post'
      },
      validLink() {
        let links = linkify.find(this.source.link.trim()).filter((l)=>{
          return l.type === 'url'
        })

        let social, SOCIALS = [{
          href:'youtube.com',
          id:'youtube',
        },{
          href:'youtu.be',
          id:'youtube',
        },{
          href:'facebook.com',
          id:'facebook',
        },{
          href:'linkedin.com',
          id:'linkedin',
        },{
          href:'x.com',
          id:'x',
        },{
          href:'instagram.com',
          id:'instagram',
        }]

        if ( links.length ) {
          social = SOCIALS.find((e)=>{
            return links[0].href.includes(e.href)
          }) 
        }

        if ( !social ) {
          this.source.error = ''
        } else if (social.id === 'youtube') {
            this.source.error = `Youtube links are not supported - to use a video please upload the file.`
        } else {
          this.source.error = `Social links are not supported - please provide a ${this.source.type === 'news-link'? 'Newsletter' : 'Blog Post'} link.`
        }

        return links.length > 0 && !this.source.error
      },
      setSource(source) {
        this.source.type = source
      },
      setChannel(channel) {
        this.source.channel = channel
      },
      onPageChange(slide) {
        //console.log('onPageChange',slide)
      },
      next(dir) {
        this.slide += dir
        if ( this.slide === 6 ) {
          let LINK = 'https://www.lately.ai/blog/lately-ais-ultimate-social-media-writing-guide-7-ways-to-inspire-action'
          if ( this.source.type === 'no-link' ) {
            this.source.link = LINK
          } else if ( this.source.link === LINK ) {
            this.source.link = ''
          }
        } 
      },
      async complete() {

        this.busy = true

        let channels = {
          valid:true,
          selectedTypes:[]
        }, frags = this.source.channel.split('-')
        channels.selectedTypes.push(frags[0])

        let config = {
          "type": "text",
          "subtype": "url",
          "tone": "natural",
          "links": {
            "all": ""
          },
          "text": {
            "url": this.source.link,
            "prompt": "",
            "pasted": "",
            "composed": false
          },
          "media": {
            "link": "",
            "intro": "",
            "outro": "",
            "source": "",
            "caption": false,
            "language": "en-US"
          },
          channels
        }
   
        let session = await GeneratorSessionHelper.createOrUpdateSession( undefined, config )

        // for business channels use a more formal tone
        let tone = this.source.channel.includes('business')? 'professional' : 'conversational'

        await actions.generate( session, tone )

        this.$emit('on-action', {
          action:'generate',
          session
        } )

      }
    },
    components: {
      Carousel,
      Slide
    }
}
</script>

